import { computed, IReactionDisposer, observable, reaction, runInAction, when } from 'mobx'
import { asyncAction } from 'mobx-utils'
import { walletStore } from '@/stores/wallet-store'
import { apiService } from '@/services/api-service'
import { FixedPoolModel } from '@/models/fixed-pool-model'
import { from, Subject, Subscription, timer } from 'rxjs'
import FixedSwapContract, { FixedSwapContractPurchase } from '@/libs/models/FixedSwapContract'
import { getContract } from '../business/swap-contract.business'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import moment from 'moment'
import { concatMap, takeUntil, takeWhile } from 'rxjs/operators'
import { FixedNumber } from '@ethersproject/bignumber'
import { promiseHelper } from '@/helpers/promise-helper'

export class PurchasedItemViewModel {
  @observable claiming = false
  @observable claimed = false
  @observable refunded = false
  @observable poolFinalized = false
  @observable currentTime = moment()

  private _unscrible = new Subject()

  constructor(
    public purchase: FixedSwapContractPurchase,
    private contract: FixedSwapContract,
    private isAtomicSwap: boolean,
    isPoolFinalized: boolean
  ) {
    this.claimed = purchase.wasFinalized
    this.poolFinalized = isPoolFinalized
    this.refunded = purchase.refunded
    if (!this.claimed && this.purchase.validAfterDate) {
      // remain 30minutes => claim => timer
      const validAfter = moment(this.purchase.validAfterDate)
      if (
        !isPoolFinalized ||
        (this.currentTime.isBefore(validAfter) &&
          this.currentTime
            .clone()
            .add(30, 'minutes')
            .isAfter(validAfter))
      ) {
        timer(0, 5000)
          .pipe(takeUntil(this._unscrible))
          .subscribe(() => {
            runInAction(() => (this.currentTime = moment()))
          })
      }
    }
  }

  @asyncAction *claimToken() {
    this.claiming = true
    const contract = this.contract
    try {
      if (this.canRedeemTokens) {
        const result = yield contract?.redeemTokens({ purchase_id: this.purchase._id })
        if (result.status) {
          snackController.success('Claim successful')
          this.claimed = true
        } else {
          snackController.error('Claim failed')
        }
      }
    } catch (error) {
      snackController.error(error.message)
    } finally {
      this.claiming = false
    }
  }

  @computed get canPoolRedeem() {
    return !this.isAtomicSwap && this.poolFinalized
  }

  @computed get canRedeemTokens() {
    let ok = this.canPoolRedeem && !!this.purchase.validAfterDate
    ok = ok && this.currentTime.isAfter(moment(this.purchase.validAfterDate))
    return ok
  }

  destroy() {
    this._unscrible.next()
    this._unscrible.complete()
  }
}

export class PoolItemViewModel {
  @observable purchases: PurchasedItemViewModel[] = []

  contract?: FixedSwapContract

  @observable loading = false
  @observable isBuyer = false
  private _checkFinializedSubscription?: Subscription

  private _disposers: IReactionDisposer[] = []

  constructor(public model: FixedPoolModel) {
    this.contract = getContract(model)
    this._disposers = [
      when(
        () => walletStore.connected,
        () => {
          if (walletStore.chainId === model.chainId) {
            this.contract?.injectMetamaskWeb3(walletStore.web3!)
          }
        }
      )
    ]
  }

  @asyncAction *checkBuyer() {
    if (!this.contract) return
    try {
      this.isBuyer = yield this.contract?.isBuyer(walletStore.account)
    } catch (error) {
      console.error(error)
    }
  }

  @asyncAction *loadDetailPurchases() {
    this.loading = true
    try {
      const contract = this.contract
      if (!contract || !this.isBuyer) return
      yield contract.assertERC20Info()
      const purchaseIds = yield this.contract?.getAddressPurchaseIds({ address: walletStore.account })
      const isAtomicSwap = yield contract.isTokenSwapAtomic()
      let isFinalized = yield contract.isFinalized()
      let purchases: FixedSwapContractPurchase[] = yield Promise.all(
        purchaseIds.map(purchase_id => this.contract?.getPurchase({ purchase_id }))
      )
      const pendingPurchase: FixedSwapContractPurchase = yield contract.getPendingPurchase()
      if (!FixedNumber.from(pendingPurchase.amount).isZero()) {
        purchases.push(pendingPurchase)
      }
      if (this.model?.tokenName === 'SON') {
        purchases = purchases.map(p => {
          return {
            ...p,
            amount: (+p.amount * 50).toString(),
            ethAmount: (+p.ethAmount / 2).toString()
          }
        })
      }
      this.purchases = purchases
        .filter(p => this.model?.tokenName !== 'SON' || (p._id as any) !== -1)
        .map((p, index) => {
          if (this.model.tokenName === 'SFEX') {
            p.validAfterDate = moment(p.validAfterDate)
              .add(4, 'hour')
              .add(10, 'minutes')
              .toDate()
            if (index === 0) p.wasFinalized = true
          }
          return new PurchasedItemViewModel(p, contract, isAtomicSwap, isFinalized)
        })
      if (!isFinalized) {
        this._checkFinializedSubscription?.unsubscribe()
        this._checkFinializedSubscription = timer(5000, 5000)
          .pipe(takeWhile(() => !isFinalized))
          .subscribe(async () => {
            isFinalized = await contract.isFinalized()
            if (isFinalized) {
              runInAction(() => this.purchases.forEach(p => (p.poolFinalized = isFinalized)))
            }
          })
      }
    } catch (err) {
      console.error(err)
    } finally {
      this.loading = false
    }
  }

  destroy() {
    this._checkFinializedSubscription?.unsubscribe()
    this.purchases.forEach(p => p.destroy())
    this._disposers.forEach(d => d())
  }

  @computed get isBslHolderType() {
    return this.model?.type !== 'v1'
  }

  @computed get tradeToken() {
    return this.model?.tradeToken || 'BNB'
  }

  @computed get claimAirdrop() {
    return this.model?.data?.claimType === 'airdrop'
  }

  @computed get chainId() {
    return this.model?.chainId
  }

  @computed get isTBARedeem() {
    return this.model?.data?.isTBARedeem
  }

  @computed get poolId() {
    return this.model?.id
  }

  @computed get forceRefund() {
    return this.model?.data?.forceRefund
  }
}

export class AllAllocationsViewModel {
  private _poolLoaderQueue = new Subject<PoolItemViewModel>()
  private _unscrible = new Subject()
  @observable loading = false
  @observable _page = 0
  private _itemsPerPage = 10

  _disposers: IReactionDisposer[]

  constructor() {
    this._disposers = [
      when(
        () => walletStore.connected,
        () => this.fetchPools()
      )
    ]
    this._poolLoaderQueue
      .pipe(
        takeUntil(this._unscrible),
        concatMap(p => from(p.loadDetailPurchases() as any))
      )
      .subscribe()
  }

  @observable allPools: PoolItemViewModel[] = []
  @observable totalCount = 0;

  @asyncAction *fetchPools() {
    this.loading = true
    try {
      if (!walletStore.connected) {
        snackController.error('Please connect wallet first')
      } else {
        let results = []
        const _start = this._page++ * this._itemsPerPage
        const params = {
          _start,
          _limit: this._itemsPerPage,
          _sort: 'index:DESC'
        }
        results = yield Promise.all([apiService.fixedPool.find(params), apiService.fixedPool.count({})])

        const allPools = (results[0] as FixedPoolModel[]).map(p => new PoolItemViewModel(p))
        if (!allPools || !allPools.length) return

        yield Promise.all(allPools.map(p => p.checkBuyer()))

        const filteredPools = [...allPools.filter(p => p.isBuyer)]
        if (!filteredPools.length) {
          yield promiseHelper.delay(500)
          yield this.fetchPools()
        } else {
          this.allPools = [...this.allPools, ...filteredPools]
          filteredPools.forEach(p => this._poolLoaderQueue.next(p))
          this.totalCount = results[1]
        }
      }
    } finally {
      this.loading = false
    }
  }

  async destroy() {
    this._unscrible.next()
    this._unscrible.complete()
    this.allPools.forEach(p => p.destroy())
  }

  @computed get canLoadMore() {
    return this.loading || !this.totalCount || this._page * this._itemsPerPage < this.totalCount
  }
}
