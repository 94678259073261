<template>
  <div class="fill-height">
    <v-container class="pa-6">
      <div class="text-h5 font-weight-bold">Your Allocations</div>
      <div class="d-flex mt-6 justify-center" v-if="!vm.loading && !vm.allPools.length">
        No items to display
      </div>
      <v-card v-for="pool in vm.allPools" outlined :key="pool.model.id" class="mt-6">
        <v-data-table
          :headers="pool.isBslHolderType ? bslheaders : headers"
          :items="pool.purchases"
          :items-per-page="-1"
          hide-default-footer
          class="purchases-table"
          :loading="pool.loading"
        >
          <template v-slot:top>
            <div class="pa-4 pb-0 d-flex">
              <div style="min-width: 50px">
                <app-logo height="24" :avatar="pool.model.logoUrl || pool.model.file" />
              </div>
              <div class="text-subtitle-2">{{ pool.model.name }}</div>
            </div>
          </template>
          <template v-slot:item.index="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <span v-if="pool.claimAirdrop">
                Automated Distribute
              </span>
              <div v-else-if="item.claimed" class="d-flex align-center">
                <span class="mr-2">Claimed</span>
              </div>
              <div v-else-if="item.refunded" class="d-flex align-center">
                <span class="mr-2">Refunded</span>
              </div>
              <div v-else-if="pool.forceRefund">
                <router-link class="blue--text" :to="`/pool/${pool.poolId}`">
                  Go to project detail and refund
                </router-link>
              </div>
              <v-btn color="primary" :disabled="true" small rounded v-else-if="!item.canRedeemTokens"
                >Claim Tokens</v-btn
              >
              <connect-metamask small v-else :requiredChainId="pool.chainId">
                <v-btn
                  :loading="item.claiming"
                  color="primary"
                  :disabled="!item.canRedeemTokens || pool.isTBARedeem"
                  small
                  rounded
                  @click="item.claimToken()"
                  >Claim Tokens</v-btn
                >
              </connect-metamask>
            </div>
          </template>
          <template v-slot:item.purchase.validAfterDate="{ item }">
            <span v-if="item.purchase.validAfterDate && !pool.isTBARedeem">
              {{ item.purchase.validAfterDate | ddmmyyyyhhmmss }}
            </span>
            <span v-else>TBA</span>
          </template>
          <template v-slot:item.purchase.ethAmount="{ item }">
            {{ item.purchase.ethAmount | formatNumber }} {{ pool.tradeToken }}
          </template>
        </v-data-table>
      </v-card>
      <div class="d-flex mt-6 justify-center">
        <v-btn v-if="!walletStore.connected" color="primary" @click="walletStore.connect()">
          Connect Wallet
        </v-btn>
        <v-btn color="primary" :loading="vm.loading" v-else-if="vm.canLoadMore" @click="vm.fetchPools()">
          Load More
          <template v-slot:loader>
            <span>Loading...</span>
          </template>
        </v-btn>
      </div>
    </v-container>
    <v-divider class="mt-7" />
    <company-footer />
  </div>
</template>

<script>
import FeaturedPoolItem from '../components/featured-pool-item.vue'
import UpcomingPoolItem from '../components/upcoming-pool-item.vue'
import UpcomingProjectItem from '../components/upcoming-project-item.vue'
import { AllAllocationsViewModel, PoolItemViewModel } from '../viewmodels/all-allocations-viewmodel'
import { Observer } from 'mobx-vue'
import { Component, Vue, Provide } from 'vue-property-decorator'
import poolState from '@/modules/ido/components/pool-state.vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    UpcomingPoolItem,
    FeaturedPoolItem,
    UpcomingProjectItem,
    poolState
  }
})
export default class AllPools extends Vue {
  @Provide() vm = new AllAllocationsViewModel()
  walletStore = walletStore
  searchKey = ''
  headers = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Date', value: 'purchase.timestamp', sortable: false },
    { text: 'Action', value: 'action', sortable: false }
  ]
  bslheaders = [
    { text: '#', value: 'index', sortable: false },
    { text: 'Token Allocation', value: 'purchase.amount', sortable: false },
    { text: 'Trade Amount', value: 'purchase.ethAmount', sortable: false },
    { text: 'Valid From', value: 'purchase.validAfterDate', sortable: false },
    { text: 'Action', value: 'action', sortable: false }
  ]

  showDetail(item) {
    if (item.pool.address) {
      this.$router.push({ path: '/pool/' + item.pool.id })
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
</script>

<style scoped lang="scss">
.progress-mobile {
  width: 120px;
}
.progress-desktop {
  width: 200px;
}
</style>
