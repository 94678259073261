var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height"},[_c('v-container',{staticClass:"pa-6"},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v("Your Allocations")]),(!_vm.vm.loading && !_vm.vm.allPools.length)?_c('div',{staticClass:"d-flex mt-6 justify-center"},[_vm._v(" No items to display ")]):_vm._e(),_vm._l((_vm.vm.allPools),function(pool){return _c('v-card',{key:pool.model.id,staticClass:"mt-6",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"purchases-table",attrs:{"headers":pool.isBslHolderType ? _vm.bslheaders : _vm.headers,"items":pool.purchases,"items-per-page":-1,"hide-default-footer":"","loading":pool.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"pa-4 pb-0 d-flex"},[_c('div',{staticStyle:{"min-width":"50px"}},[_c('app-logo',{attrs:{"height":"24","avatar":pool.model.logoUrl || pool.model.file}})],1),_c('div',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(pool.model.name))])])]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',[(pool.claimAirdrop)?_c('span',[_vm._v(" Automated Distribute ")]):(item.claimed)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Claimed")])]):(item.refunded)?_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Refunded")])]):(pool.forceRefund)?_c('div',[_c('router-link',{staticClass:"blue--text",attrs:{"to":("/pool/" + (pool.poolId))}},[_vm._v(" Go to project detail and refund ")])],1):(!item.canRedeemTokens)?_c('v-btn',{attrs:{"color":"primary","disabled":true,"small":"","rounded":""}},[_vm._v("Claim Tokens")]):_c('connect-metamask',{attrs:{"small":"","requiredChainId":pool.chainId}},[_c('v-btn',{attrs:{"loading":item.claiming,"color":"primary","disabled":!item.canRedeemTokens || pool.isTBARedeem,"small":"","rounded":""},on:{"click":function($event){return item.claimToken()}}},[_vm._v("Claim Tokens")])],1)],1)]}},{key:"item.purchase.validAfterDate",fn:function(ref){
var item = ref.item;
return [(item.purchase.validAfterDate && !pool.isTBARedeem)?_c('span',[_vm._v(" "+_vm._s(_vm._f("ddmmyyyyhhmmss")(item.purchase.validAfterDate))+" ")]):_c('span',[_vm._v("TBA")])]}},{key:"item.purchase.ethAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.purchase.ethAmount))+" "+_vm._s(pool.tradeToken)+" ")]}}],null,true)})],1)}),_c('div',{staticClass:"d-flex mt-6 justify-center"},[(!_vm.walletStore.connected)?_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.walletStore.connect()}}},[_vm._v(" Connect Wallet ")]):(_vm.vm.canLoadMore)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.vm.loading},on:{"click":function($event){return _vm.vm.fetchPools()}},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('span',[_vm._v("Loading...")])]},proxy:true}])},[_vm._v(" Load More ")]):_vm._e()],1)],2),_c('v-divider',{staticClass:"mt-7"}),_c('company-footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }